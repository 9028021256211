import React, { useState, useRef, useEffect } from "react"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import { Link } from "gatsby"

const ProductAreaHeader = ({ title, normalImage, wideImage, breadcrumbs }) => {
  return (
    <section className="relative lg:mb-16 bg-gray-800 md:h-80">
      <div className="h-full mx-auto text-white max-w-screen-2xl">
          <div className="w-full relative h-80">
            <div className="w-full py-8 px-4 absolute z-10">
              <div className="block mb-2 -ml-2 text-sm">
                {breadcrumbs?.map((breadcrumb, index) => (
                  <span key={index}>
                    {index > 0 && (
                      <span className="inline-block h-4 text-gray-600">
                        /
                      </span>
                    )}
                    <Link
                      className={`px-2 hover:underline text-gray-200`}
                      key={index}
                      to={breadcrumb.link}
                    >
                      {breadcrumb.link_text}
                    </Link>
                  </span>
                ))}
              </div>
              <Headline
                className="text-white"
                headline={title}
                headlineLevel="h2"
                tagName="h1"
              />
            </div>
            <div className="invisible lg:visible absolute inset-0" style={{
              backgroundImage: `url(${wideImage})`,
              backgroundPosition: "center center",
              backgroundSize: "cover"
            }}>
              <div
                className="absolute inset-0"
                style={{
                  background:
                    "linear-gradient(to right, rgb(33, 39, 42) 1%, transparent 20%, transparent 87%, rgb(33, 39, 42))",
                }}
              ></div>
            </div>
            <div className="visible lg:invisible absolute inset-0" style={{
              backgroundImage: `url(${normalImage})`,
              backgroundPosition: "center center",
              backgroundSize: "cover"
            }}>
              <div
                className="absolute inset-0"
                style={{
                  background:
                    "linear-gradient(-60deg, transparent 0%, transparent 40%, #21272a 100%)",
                }}
              ></div>
            </div>
          </div>
      </div>
    </section>
  )
}

export default ProductAreaHeader
